// 引入request
import requests from "../../Utils/request";
// 审批表单
// export const getApprovalForm = params => {
//   return request({
//     // 请求地址，会自动匹配baseurl
//     url: '/approve/getApprovalForm',
//     // 请求方式
//     method: 'GET',
//     // 请求参数
//     params
//   })
// }

// 对外暴露一个函数，只要外部调用这个函数，就会向服务器发起ajax请求、获取三级菜单数据；该函数返回  服务器返回的结果

// 登录
export const cjlogin = (data) =>
  requests({
    url: "/auth/login",
    method: "post",
    data: data,
  });

// 注册
export const cjlogout = () =>
  requests({
    url: "/auth/logout",
    method: "get",
    // params: jquer
  });
// 获取用户信息
export const getUser = () =>
  requests({
    url: "/account/member",
    method: "get",
  });

// 员工手册
export const cjEmployeeHandbook = () =>
  requests({
    url: "/document/file/cate",
    method: "get",
  });

// 获取过滤文书资源库数据
export const getFileList = (data) =>
  requests({
    url: `document/file/list?PageIndex=${data.pageIndex}&PageSize=${data.pageSize}`,
    method: "post",
    data: data.data,
  });

// 获取文书资源库详情
export const getFileDetail = (params) =>
  requests({
    url: `document/file/detail?id=${params.id}&filetype=${params.type}`,
    method: "get",
  });

// 获取hr规章分类
export const getHRcate = () =>
  requests({
    url: "/document/hr/cate",
    method: "get",
  });
// HR规章数据查询
export const getHRList = (data) =>
  requests({
    url: `document/hr/list?PageIndex=${data.pageIndex}&PageSize=${data.pageSize}`,
    method: "post",
    data: data.data,
  });
// 获取文书资源库详情
export const getHRDetail = (id) =>
  requests({
    url: `document/hr/detail?id=${id}`,
    method: "get",
  });
// 获取过滤文书资源库数据
// export function getFileList(data) {
//   return request({
//     url: `api/document/file/list?PageIndex=${data.pageIndex}&PageSize=${data.pageSize}`,
//     method: "post",
//     data: data.data,
//   });
// }
// 入职风险点
export const cjboxes = () =>
  requests({
    url: "/document/file/risk/list",
    method: "get",
    // params
  });

//法规政策库
//获取城市集合
export const cjctiyLaw = () =>
  requests({
    url: "/laborlaw/search/cities",
    method: "get",
    // params
  });

//获取最新几条劳动法数据库
export const cjNewLawList = () =>
  requests({
    url: "laborlaw/search/law/last",
    method: "get",
    // params: query
  });

//查询劳动法数据列表
export const cjlabourLawList = (params) =>
  requests({
    url: `laborlaw/search/law?pageindex=${params.pageIndex}&pagesize=${params.pageSize}`,
    method: "post",
    data: params.data,
  });

// 获取数据库详情
export const getLaborLawsDetail = (data) =>
  requests({
    url: `laborlaw/search/law/detail?id=${data.id}`,
    method: "get",
  });

// 获取查询条件集合
export const cjcaseLaw = () =>
  requests({
    url: "/laborlaw/search/caselaw/cate",
    method: "get",
    // params
  });

// 通过筛选条件查询判例
export const cjcaseLawList = (data) =>
  requests({
    url: `laborlaw/search/caselaw/list?pageindex=${data.index}&pagesize=${data.size}`,
    method: "post",
    data,
  });
// 获取判例详情
export const getDetail = (params) =>
  requests({
    url: `laborlaw/search/caselaw/detail?serailno=${params.a0}`,
    method: "get",
  });

//劳动法参数
//获取查询条件参数
export const cjcaseparameter = () =>
  requests({
    url: "/laborlaw/search/laborlaw/params",
    method: "get",
    // params
  });
//按条件查询劳动法参数数据
export const getLaborLawParamsForm = (data) =>
  requests({
    url: "/laborlaw/search/laborlaw/params",
    method: "post",
    data,
  });
// 获取计算器token
export const getCounterToken = () =>
  requests({
    url: "/laborlaw/search/cal/auth",
    method: "get",
  });
// 获取任务类型列表
// export const getSelectList = () =>
//   requests({
//     url: `/api/assignment/type/list`,
//     method: "get",
//   });
export const getSelectList = (params) =>
  requests({
    url: `/api/common/AssignmentType/list?customerId=${params}`,
    method: "get",
  });
//获取上传Policy
export const getPolicyToken = (data) =>
  requests({
    url: "/api/common/policy/token",
    method: "post",
    data,
  });
// 上传文件返路径
export const uploadFile = (data) =>
  requests({
    url: "/api/assignment/import",
    method: "post",
    data,
  });
// 获取省市数据
export const getAddress = () =>
  requests({
    url: "/api/common/address",
    method: "get",
  });
  // 仪表盘数据------------------------
  // 获取任务统计数据
  export const getDashboard = () =>
    requests({
      url: "/api/dashboard/project/statistics",
      method: "get",
    });
      // 获取本周服务时长统计
  export const getWeekDuration = () =>
  requests({
    url: "/api/dashboard/serivce/duration/week",
    method: "get",
  });
    // 获取月度客户排行
    export const getCustomerRanking = () =>
    requests({
      url: "/api/dashboard/customer/ranking/3",
      method: "get",
    });
    // 获取客户分布
    export const getCustomerDistribution = () =>
    requests({
      url: "/api/dashboard/customer/distribution/10",
      method: "get",
    });
        // 获取服务记录
        export const getServiceLinear = (params) =>
        requests({
          url: `/api/dashboard/service/linear${params}`,
          method: "get",
        });
// 上传客户文件单独接口
export const addCustomerFile = (data) =>
requests({
  url: "/api/customer/file/add",
  method: "post",
  data,
}); 
// 获取oss临时地址
export const getFileUrl = (data) =>
requests({
  url: "/api/common/file/uri",
  method: "post",
  data,
}); 
