<template>
  <b-container fluid>
    <div v-show="!showDetails">
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:body>
            <div class="iq-search-bar">
              <form action="#" class="">
                <p class="all-crumbs">文书资源 > 劳动合同与协议</p>
                <label for="validationDefault01">关键字</label>
                <input
                  type="text"
                  class="text search-input"
                  style="cursor: pointer"
                  v-model="globalSearch"
                  placeholder="请输入关键词"
                />
                <el-button
                  class="btn mr-1 btn-primary rounded-pill seeAbout"
                  type="submit"
                  @click="getFileListInfo"
                >
                  查询
                </el-button>
                <el-button
                  class="btn mr-1 btn-secondary rounded-pill reset"
                  type="submit"
                  @click="reset"
                >
                  重置
                </el-button>
              </form>
            </div>
          </template>
        </iq-card>
      </b-col>
      <b-col lg="3">
        <iq-card class="contract">
          <template v-slot:headerTitle>
            <h4 class="card-title" style="color: #837af3">劳动合同与协议</h4>
          </template>
          <template v-slot:body>
            <template
              v-for="(item, index) in contractCheckbox"
              style="background: white"
            >
              <b-checkbox
                v-model="item.isLocal"
                :disabled="item.disabled"
                name="check-button"
                inline
                :key="index"
                style="width: 100%"
                @change="changeBtn(item)"
              >
                {{ item.title }}
              </b-checkbox>
            </template>
          </template>
        </iq-card>
      </b-col>
      <b-col lg="9">
        <b-row class="roww">
          <template v-if="excelFileType.length != 0">
            <b-col
              lg="2"
              v-for="(txtType, index) in excelFileType"
              :key="index"
            >
            <div @click="toDetails(txtType)">
              <iq-card
                style="padding: 0.5rem 0.5rem 0.2rem;"
              >
                <div class="txtType">
                  <img src="../../assets/images/home/word.png" alt="" />
                </div>
                <p class="txtTitle" :title="txtType.title">{{ txtType.title }}</p>
              </iq-card>
              </div>
            </b-col>
          </template>
          <p v-else>暂无数据</p>
          <div class="btn" v-if="dataSize > excelFileType.length">
            <button @click="more">加载更多</button>
          </div>
        </b-row>
      </b-col>
    </b-row>
    </div>
    <detailsPage v-if="showDetails" ref="details"></detailsPage>
  </b-container>
</template>

<script>
import { core } from "../../config/pluginInit";

// 引入函数
import { cjEmployeeHandbook, getFileList } from "@/FackApi/api/index";

import detailsPage from './details/detailsPage';
export default {
  name: "FormValidates",
  components: {
    detailsPage,
  },
  mounted() {
    core.index();
  },
  data() {
    return {
      contractCheckbox: [],
      checkVal: [],
      excelFileType: [],
      globalSearch: "",
      showSearch: false,
      showMenu: false,
      currentPage: 1,
      interPage: 20,
      dataSize: 0,
      showDetails: false,
    };
  },
  created() {
    // 第三步 因为是自定义的getUserList方法 所以需要在钩子函数中调用才行！
    this.getAppForm();
  },
  methods: {
    // 接口请求方法
    getAppForm() {
      cjEmployeeHandbook().then((res) => {
        if (res.code === 0) {
          this.contractCheckbox = res.data.lcb;
          this.contractCheckbox.forEach((item) => {
            item.isLocal = false;
          });
          this.getFileListInfo();
        }
      });
    },
    reset() {
      this.globalSearch = "";
      this.checkVal = [];
      this.dataSize = 0;
      this.contractCheckbox.forEach((item) => {
        item.isLocal = false;
      });
    },
    changeBtn(val) {
      this.checkVal = [];
      this.dataSize = 0;
      this.excelFileType = [];
      if (val.checked === true) {
        this.checkVal.push(val);
      } else {
        this.checkVal.splice(val, 1);
      }
      this.getFileListInfo();
    },
    getFileListInfo() {
      let list = [];
      this.checkVal = [];
      this.contractCheckbox.forEach((item) => {
        if (item.isLocal) {
          list.push(item.id);
          this.checkVal.push(item.title);
        }
      });
      if (list.length < 1 && !this.globalSearch) return;
      const params = {
        data: {
          ftype: "L",
          ids: list,
          keys: this.globalSearch,
        },
        pageIndex: this.currentPage,
        pageSize: this.interPage,
      };
      getFileList(params).then((res) => {
        if (res.code === 0) {
          this.excelFileType = this.excelFileType.concat(res.data);
          this.dataSize = res.page.totalCount;
        }
      });
    },
    more() {
      this.currentPage++;
      this.getFileListInfo();
    },
    toDetails(item) {
      // console.log(item);
      this.showDetails = true;
      item.type = 'L';
      this.$nextTick(() => {
        this.$refs.details.getFileDetailInfo(item);
      });
    },
  },
};
</script>
<style lang="less" scoped>
.iq-search-bar {
  // border-bottom: 1px solid #e7e7e7;
  // padding-bottom: 30px !important;
  // margin-bottom: 30px;
}
.container-fluid {
  margin-top: 20px;
}
.search-input {
  width: 300px !important;
  margin: 0 50px 0 10px !important;
  background: #eaf5ff !important;
}
.seeAbout {
  width: 100px;
  border-radius: 50px;
}
.reset {
  width: 100px;
  border-radius: 50px;
  background: #c0c4cc;
  margin-left: 10px !important;
}
.selectedClassName {
  padding: 5px 20px;
  margin-right: 10px;
  color: #837af3;
  font-size: 0.8em;
  display: inline-block;
  border-radius: 50px;
  background: linear-gradient(to right, #f0e4fd, #e6e4fd) !important;
}
.txtType {
  width: 100%;
  text-align: center;
  img {
    width: 5rem;
    margin: 2rem auto 1rem;
  }
}
.txtTitle {
  height: 2rem;
  // line-height: 2rem;
  font-size: 1em;
  color: #333333;
  padding: 0 1rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
}
.roww {
  // max-height: 600px;
  padding-bottom: 30px;
  overflow-y: auto;
  .btn {
    width: 100%;
    button {
      height: 26px;
      border-radius: 14px;
      color: #a67af3;
      border: 1px solid #a67af3;
      background-color: #fff;
      padding: 0 20px;
      margin: 20px auto 0;
      cursor: pointer;
    }
  }
}
.all-crumbs {
  margin-bottom: 20px !important;
}
// .iq-card-body {
//   height: 85% !important;
// }
</style>
<style>
.contract {
  border-radius: 10px;
  overflow: hidden;
  padding: 0 !important;
  background: linear-gradient(to right, #f0e4fd, #e6e4fd) !important;
}
.contract .iq-card-body {
  background: white;
  min-height: 500px !important;
}
</style>
