// 对axios进行二次封装
import axios from 'axios'
// import { getToken } from '@/Utils/auth'
import { getToken, removeToken } from '../Utils/token'
import { toiLaaS } from '../Utils/index'
import { Message } from 'element-ui'

// 1. 利用axios对象的方法create，去创建一个axios案例
// 2. requests就是axios
const requests = axios.create({
// 配置对象
  baseURL: '/apis',
  // baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  timeout: 100000 // 请求超时的时间5s
  // headers: {'X-Custom-Header': 'foobar'}
})

// 请求拦截器：在发送请求之前，请求拦截器可以检测到，在请求发送之前处理一些事情
requests.interceptors.request.use(
  // (config) =>{
  // config: 是配置对象，该对象包含一个属性-->headers请求头
  config => {
    // 携带token，从本地读取到token，然后添加到请求头
    // const token = localStorage.getItem('token')
    // // config.headers.common['Authorization'] = `Bearer ${token}`//设置请求头
    // token && (config.headers.Authorization = token)

    if (getToken()) {
      config.headers.Authorization = 'Bearer ' + getToken()
    }
    return config
  },
  error => {
    // message.error(error.message)
    return Promise.reject(error)
  }
  // return config
// }
)

// 响应拦截器：
requests.interceptors.response.use(
//   // (res) => {
//   // // 成功的回调函数
//   //   return res.data
//   // }, (_error) => {
//   //   return Promise.reject(new Error('faile'))
//   // }
//   config => {
//     // 携带token，从本地读取到token，然后添加到请求头
//     // const token = localStorage.getItem('token')
//     // token && (config.headers.Authorization = token)
//     // return config

  //     if(config.data.code == 401){
  //       sessionStorage.removeItem('token')
  //       // router.push('/Signln1')
  //       this.$router.replace('/auth/sign-in1')
  //       location.reload()
  //     }
  //     // if(config.data.code == 200 ) message.error(config.data.message)
  //     return config.data
  //   },
  //   error => {
  //     // message.error(error.message)
  //     return Promise.reject(error)
  //   }
  // )

  response => {
    const res = response.data
    if (res.code != '0' && res.code) {
      Message({
        message: res.msg || res.message || 'Error',
        type: 'error',
        duration: 3 * 1000
      })
    }
    return res
  },
  error => {
  // console.log(error.response);
  // token 过期多次提示处理
    if (localStorage.getItem('tokenStatus') == '1') return
    const message = error.response.data.message || '网络异常'
    Message({
      message: message,
      type: 'error',
      duration: 3 * 1000
    })
    if (error.response.status == 401) {
      localStorage.setItem('tokenStatus', '1')
      Message({
        message: '登录超时，请重新登录！',
        type: 'error',
        duration: 3 * 1000
      })
      removeToken()
      setTimeout(() => {
        localStorage.setItem('tokenStatus', '0')
        toiLaaS('login')
      }, 1500)
      return
    }
    return Promise.reject(error)
  }
)

// 对外暴露
export default requests
