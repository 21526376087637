<template>
  <div class="details-page">
    <div class="btns">
      <button @click="secede">返回</button>
    </div>
    <p class="center" v-html="this.myInfo"></p>
  </div>
</template>

<script>
import { getFileDetail, getHRDetail } from "../../../FackApi/api/index";
export default {
  name: "detailsPage",
  components: {},
  data() {
    return {
      myInfo: "",
    };
  },
  created() {},
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    async getFileDetailInfo(info) {
      // console.log(info);
      let res = await getFileDetail(info);
      // console.log(res);
      if (res.code == 0) {
        this.myInfo = res.data.content;
      }
    },
    async getHRDetailInfo(info) {
      // console.log(info);
      let res = await getHRDetail(info.id);
      // console.log(res);
      if (res.code == 0) {
        this.myInfo = res.data.content;
      }
    },
    secede() {
      this.$parent.showDetails = false;
    },
  },
};
</script>

<style lang="less" scoped>
.details-page {
  padding: 20px 0;
  // max-height: 700px;
  overflow-y: auto;
  background-color: #fff;
  margin-bottom: 20px;
  .btns {
    display: flex;
    justify-content: space-between;
    width: 900px;
    margin: 40px auto 0;

    button {
      width: 96px;
      height: 34px;
      font-size: 14px;
      color: #000;
      cursor: pointer;
      border-radius: 17px;
      border: 1px solid #999;
      background-color: #fff;
    }
  }
  .center {
    width: 900px;
    margin: 50px auto 0;
    font-size: 14px;
  }
}
</style>
